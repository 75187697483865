import React from "react"

import Playlist from "../components/playlist"

import imgbanner from "../images/index/thumb/sanity.jpg"
import allcategories from "../content/categorytitles.json"
import videolist from "../content/playlistsanity.json"


const SanityPage = ({location}) => (
  <Playlist
    location={location}
    headingClassName={"color-text-primary"}
    path={"sanity"}
    bannerimg={imgbanner}
    title={allcategories.sanity.title}
    writeup={allcategories.sanity.description} 
    videolist={videolist} />
)

export default SanityPage
